import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '@/store'

import LayoutPublic from '@/layouts/public/LayoutPublic.vue'
import LayoutDashboard from '@/layouts/LayoutDashboard.vue'
import LayoutOnboarding from '@/layouts/LayoutOnboarding.vue'

import Dashboard from '@/views/Dashboard.vue'
import OnboardingSoftwareMFA from '@/views/OnboardingSoftwareMFA.vue'
import OnboardingBuyer from '@/views/OnboardingBuyer.vue'
import OnboardingSeller from '@/views/OnboardingSeller.vue'
import Campaigns from '@/components/buyer/campaign/Campaigns.vue'
import PaymentMethods from '@/components/billing/PaymentMethods.vue'
import AddCreditCard from '@/components/billing/AddCreditCard.vue'
import UploadKYCDocs from '@/components/billing/UploadKYCDocs.vue'
import BillingSettings from '@/components/billing/BillingSettings.vue'
import MyAccount from '@/components/billing/MyAccount.vue'
import AddFunds from '@/views/AddFunds.vue'
import AdvancePay from '@/views/AdvancePay.vue'

import TwoFactorAuth from '@/components/account/TwoFactorAuth.vue'
import UserManagement from '@/components/account/UserManagement.vue'

import BuyerCreateCampaign from '@/components/buyer/campaign/BuyerCreateCampaign.vue'
import ListBuyerCampaigns from '@/components/buyer/campaign/ListBuyerCampaigns.vue'
import BuyerDashboard from '@/components/buyer/dashboard/BuyerDashboard.vue'
import BuyerCallLogs from '@/components/buyer/BuyerCallLogs.vue'
import BuyerChannelBidding from '@/components/buyer/BuyerChannelBidding.vue'
import BuyerEditCampaign from '@/components/buyer/campaign/BuyerEditCampaign.vue'
import BlockedChannels from '@/components/buyer/BlockedChannels.vue'

import ListApiTokens from '@/components/api-tokens/ListApiTokens.vue'
import CreateApiToken from '@/components/api-tokens/CreateApiToken.vue'

import SellerDashboard from '@/components/seller/dashboard/SellerDashboard.vue'
import SellerAvailableOffers from '@/components/seller/SellerAvailableOffers.vue'
import SellerCreatives from '@/components/seller/creatives/SellerCreatives.vue'
import UploadCreative from '@/components/seller/creatives/UploadCreative.vue'
import ReviewCreatives from '@/components/buyer/creatives/ReviewCreatives.vue'
import BankInfo from '@/components/seller/BankInfoPanel.vue'
import SellerCallLogs from '@/components/seller/SellerCallLogs.vue'

import AccessToNoAccount from '../views/AccessToNoAccount.vue'
import AdminApprovalPending from '../views/AdminApprovalPending.vue'
import RegistrationRequestSuccess from '../views/RegistrationRequestSuccess.vue'
import PageNotFound from '../views/PageNotFound.vue'
import { BUYER_GROUP, SELLER_GROUP, SELLER_ONBOARDING_STATUS } from '@/store/modules/auth.store'
import { retryUntil } from '@/utils/retryUntil'
import BuyerAccountBudget from '@/components/buyer/BuyerAccountBudget.vue'
import AccountTimezone from '@/components/account/Timezone.vue'

function checkIfRouteHasGroup(isAuthenticated, to) {
  if (isAuthenticated && to.meta.accessUserGroups?.length) {
    const userData = store.state.auth.userDetails

    const currentAccountType = store.getters['auth/lastAccountAccessedType']

    const userHasAccess = checkUserGroup(userData?.groups, to.meta.accessUserGroups)

    const currentAccountHasAccess = to.meta.accessUserGroups.includes(currentAccountType)

    return currentAccountHasAccess && userHasAccess
  }

  return true
}

function checkUserGroup(userGroups, allowedAccessGroup) {
  return userGroups?.some(r=> allowedAccessGroup.includes(r))
}

async function checkAndFetchCurrenctAccountDetails() {
  const haveUserCurrentAccountDetails = store.getters['auth/haveUserCurrentAccountDetails']

  if (!haveUserCurrentAccountDetails) {
    await store.dispatch('auth/fetchUsersAccountDetails')
  }
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: '/dashboard',
    meta: {
      requiresAuth: true
    }
  },
  {
    name: 'Login',
    path: '/login',
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/Login.vue'),
    meta: {
      requiresAuth: false,
      redirectIfAlreadyLoggedIn: true,
      layout: LayoutPublic
    }
  },
  {
    name: 'Logout',
    path: '/logout',
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/Logout.vue')
  },
  {
    path: '/register',
    name: 'Register',
    component: () =>
      import(
        /* webpackChunkName: "buyer-registration" */ '../views/Register.vue'
      ),
    meta: {
      requiresAuth: false,
      layout: LayoutPublic
    }
  },
  {
    path: '/forgot-password',
    name: 'PasswordReset',
    component: () =>
      import(
        /* webpackChunkName: "forgot-password" */ '../views/PasswordReset.vue'
      ),
    meta: {
      requiresAuth: false,
      layout: LayoutPublic
    }
  },
  {
    path: '/buyer-registration-apply',
    name: 'BuyerRegistrationApply',
    component: () =>
      import(
        /* webpackChunkName: "buyer-registration" */ '../views/BuyerRegistrationApply.vue'
      ),
    meta: {
      requiresAuth: false,
      layout: LayoutPublic
    }
  },
  {
    path: '/seller-registration-apply',
    name: 'SellerRegistrationApply',
    component: () =>
      import(
        /* webpackChunkName: "buyer-registration" */ '../views/SellerRegistrationApply.vue'
      ),
    meta: {
      requiresAuth: false,
      layout: LayoutPublic
    }
  },
  {
    path: '/registration-success',
    name: 'RegistrationSuccess',
    component: RegistrationRequestSuccess,
    meta: {
      requiresAuth: false,
      layout: LayoutPublic
    }
  },

  {
    path: '/test/buyer-channel-bidding/:campaignId',
    name: 'BuyerChannelBidding',
    component: BuyerChannelBidding,
    meta: {
      requiresAuth: false,
      layout: LayoutDashboard
    }
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      requiresAuth: true,
      layout: LayoutDashboard
    },
    beforeEnter: async (to, from, next) => {
      try {
        await checkAndFetchCurrenctAccountDetails()
      } catch(error) {
        // TODO: Get a specific error code for situation where user has access to no accounts, instead of using error message which is bound to change
        if (error.response.data.title === 'Account ID not provided') {
          next({name: 'AccessToNoAccount'})
          return
        }
      }

      await store.cache.dispatch('auth/fetchCurrentAccountRequirements')

      const isAdminApprovalPending = store.getters['auth/isAdminApprovalPending']

      if (isAdminApprovalPending) {
        next({ name: 'AdminApprovalPending' })
      }

      const accountDetails = store.getters['auth/accountDetails']

      const isBuyerAccount = store.getters['auth/isBuyerAccount']
      const isSellerAccount = store.getters['auth/isSellerAccount']

      
      if (!accountDetails) {
        next({ name: 'Login' })
      }

      if (accountDetails && accountDetails.onboardingStatus === 'Completed') {
        if (isBuyerAccount) {
          store.dispatch('billing/getBuyerAccountBalance')
        }
        
        if (to.fullPath === '/dashboard') {
          if (isBuyerAccount) {
            next({
              name: 'ListBuyerCampaigns'
            })
          } else if (isSellerAccount) {
            next({
              name: 'SellerAvailableOffers'
            })
          } else {
            next()
          }
        } else {
          next()
        }
      } else {
        if (isBuyerAccount) {
          if (to.fullPath === '/dashboard') {
            next({
              name: 'OnboardingBuyer'
            })
          } else {
            next()
          }
        } else if (isSellerAccount) {
          next({
            name: 'OnboardingSeller'
          })
        }
      }
    },
    children: [
      {
        path: 'buyer',
        name: 'BuyerDashboard',
        component: BuyerDashboard
      },
      {
        path: 'campaigns',
        name: 'Campaigns',
        component: Campaigns
      },
      {
        path: 'create-campaign/buyer',
        name: 'BuyerCreateCampaign',
        component: BuyerCreateCampaign,
        beforeEnter: async (to, from, next) => {
          if (!store.getters['auth/isMinimumInitialDepositRequirementSatisfied']) {
            // Check requirements 5 times at an interval of 800ms
            await retryUntil(async () => {
              await store.dispatch('auth/fetchCurrentAccountRequirements')
            }, () => {
              return store.getters['auth/isMinimumInitialDepositRequirementSatisfied']
            }, 5, 800)()
          }

          next()
        },
        meta: {
          accessUserGroups: [BUYER_GROUP]
        }
      },
      {
        path: 'billing/payment-methods',
        name: 'PaymentMethods',
        component: PaymentMethods,
        meta: {
          accessUserGroups: [BUYER_GROUP]
        }
      },
      {
        path: 'billing/payment-methods/:paymentMethodId/submit-kyc',
        name: 'UploadKYCDocs',
        component: UploadKYCDocs,
        meta: {
          accessUserGroups: [BUYER_GROUP]
        }
      },
      {
        path: 'billing/add-credit-card',
        name: 'AddCreditCard',
        component: AddCreditCard,
        meta: {
          accessUserGroups: [BUYER_GROUP]
        }
      },
      {
        path: 'billing/settings',
        name: 'BillingSettings',
        component: BillingSettings,
        meta: {
          accessUserGroups: [BUYER_GROUP]
        }
      },
      {
        path: 'billing/my-account',
        name: 'MyAccount',
        component: MyAccount
      },
      {
        path: 'billing/add-funds',
        name: 'AddFunds',
        component: AddFunds,
        meta: {
          accessUserGroups: [BUYER_GROUP]
        }
      },
      {
        path: 'billing/advance-pay',
        name: 'AdvancePay',
        component: AdvancePay,
        meta: {
          accessUserGroups: [BUYER_GROUP]
        }
      },
      {
        path: '/account/2fa',
        name: 'TwoFactorAuth',
        component: TwoFactorAuth
      },
      {
        path: '/account/user-management',
        name: 'UserManagement',
        component: UserManagement
      },
      {
        path: '/account/budget',
        name: 'BuyerAccountBudget',
        component: BuyerAccountBudget
      },
      {
        path: '/account/timezone',
        name: 'AccountTimezone',
        component: AccountTimezone
      },
      {
        path: 'billing/bank-info',
        name: 'BankInfo',
        component: BankInfo,
        meta: {
          accessUserGroups: [SELLER_GROUP]
        }
      },
      {
        path: 'campaigns/seller-available-offers',
        name: 'SellerAvailableOffers',
        component: SellerAvailableOffers,
        meta: {
          accessUserGroups: [SELLER_GROUP]
        },
        props: route => ({ sellerOfferId: route.query.sellerOfferId })
      },
      {
        path: 'seller/creatives',
        name: 'SellerCreatives',
        component: SellerCreatives,
        meta: {
          accessUserGroups: [SELLER_GROUP]
        }
      },
      {
        path: 'seller/creatives/upload-creative',
        name: 'UploadCreative',
        component: UploadCreative,
        meta: {
          accessUserGroups: [SELLER_GROUP]
        }
      },      
      {
        path: 'buyer/review-creatives',
        name: 'ReviewCreatives',
        component: ReviewCreatives,
        meta: {
          accessUserGroups: [BUYER_GROUP]
        }
      },      
      {
        path: 'campaigns/buyer',
        name: 'ListBuyerCampaigns',
        component: ListBuyerCampaigns,
        meta: {
          accessUserGroups: [BUYER_GROUP]
        }
      },
      {
        path: 'edit-campaign/buyer/:id',
        name: 'BuyerEditCampaign',
        component: BuyerEditCampaign,
        meta: {
          accessUserGroups: [BUYER_GROUP]
        },
        props: true
      },

      {
        path: 'seller',
        name: 'SellerDashboard',
        component: SellerDashboard,
      },

      {
        path: 'seller/call-logs',
        name: 'SellerCallLogs',
        component: SellerCallLogs,
        meta: {
          accessUserGroups: [SELLER_GROUP]
        }
      },

      {
        path: 'buyer/call-logs',
        name: 'BuyerCallLogs',
        component: BuyerCallLogs,
        meta: {
          accessUserGroups: [BUYER_GROUP]
        },
      },

      {
        path: 'buyer/blocked-channels',
        name: 'BlockedChannels',
        component: BlockedChannels,
        meta: {
          accessUserGroups: [BUYER_GROUP]
        }
      },

      {
        path: 'tokens',
        name: 'ListApiTokens',
        component: ListApiTokens,
        meta: {
          accessUserGroups: [BUYER_GROUP, SELLER_GROUP]
        }
      },

      {
        path: 'tokens/create',
        name: 'CreateApiToken',
        component: CreateApiToken,
        meta: {
          accessUserGroups: [BUYER_GROUP, SELLER_GROUP]
        }
      },
    ]
  },
  {
    path: '/onboarding/buyer',
    name: 'OnboardingBuyer',
    component: OnboardingBuyer,
    props: route => ({ folderId: route.query.folderId, documentStatus: route.query.event }),
    meta: {
      requiresAuth: true,
      layout: LayoutOnboarding,
      accessUserGroups: [BUYER_GROUP]
    },
    beforeEnter: async (to, from, next) => {
      await checkAndFetchCurrenctAccountDetails()

      const accountOnboardingStatus = store.getters['auth/accountOnboardingStatus']

      if (accountOnboardingStatus === SELLER_ONBOARDING_STATUS.COMPLETED) {
        next({
          name: 'Dashboard'
        })
      } else {
        next()
      }
    }
  },
  {
    path: '/onboarding/seller',
    name: 'OnboardingSeller',
    component: OnboardingSeller,
    props: route => ({ folderId: route.query.folderId, documentStatus: route.query.event }),
    meta: {
      requiresAuth: true,
      layout: LayoutOnboarding,
      accessUserGroups: [SELLER_GROUP]
    },
    beforeEnter: async (to, from, next) => {
      await checkAndFetchCurrenctAccountDetails()

      const accountOnboardingStatus = store.getters['auth/accountOnboardingStatus']

      if (accountOnboardingStatus === SELLER_ONBOARDING_STATUS.COMPLETED) {
        next({
          name: 'Dashboard'
        })
      } else {
        next()
      }
    }
  },

  {
    path: '/onboarding/software-mfa',
    name: 'OnboardingSoftwareMFA',
    component: OnboardingSoftwareMFA,
    meta: {
      requiresAuth: true,
      layout: LayoutOnboarding,
    },
    beforeEnter: async (to, from, next) => {
      const isMFASetup = store.getters['auth/isMFASetup']

      if (isMFASetup) {
        next({
          name: 'Dashboard'
        })
      } else {
        next()
      }

    }
  },

  {
    path: '/no-account',
    name: 'AccessToNoAccount',
    component: AccessToNoAccount,
    meta: {
      requiresAuth: true,
      layout: LayoutOnboarding,
    },
  },

  {
    path: '/approval-pending',
    name: 'AdminApprovalPending',
    component: AdminApprovalPending,
    meta: {
      requiresAuth: true,
      layout: LayoutOnboarding,
    },
    beforeEnter: async (to, from, next) => {
      try {
        await checkAndFetchCurrenctAccountDetails()

      } catch(error) {
        // TODO: Get a specific error code for situation where user has access to no accounts, instead of using error message which is bound to change
        if (error.response.data.title === 'Account ID not provided') {
          next({name: 'AccessToNoAccount'})
          return
        }
      }

      await store.cache.dispatch('auth/fetchCurrentAccountRequirements')

      const isAdminApprovalPending = store.getters['auth/isAdminApprovalPending']

      if (!isAdminApprovalPending) {
        next({ name: 'Dashboard' })
      }

      next()
    }
  },

  { path: '*', component: PageNotFound }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

function checkMFASetup(next, to) {
  const isMFASetup = store.getters['auth/isMFASetup']

  if (!isMFASetup && to.name !== 'OnboardingSoftwareMFA' && to.matched.some((record) => record.meta.requiresAuth)) {
    next({
      name: 'OnboardingSoftwareMFA'
    })
  } else {
    next()
  }
}

router.beforeEach(async (to, from, next) => {
  const isAuthenticated = store.getters['auth/isAuthenticated']
  const haveUserData = store.getters['auth/haveUserData']

  if (isAuthenticated && !haveUserData) {
    await store.dispatch('auth/getLoggedInUserDetails')
  }

  if (to.matched.some(record => record.meta.requiresAuth) && !isAuthenticated) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    next({
      name: 'Login',
      query: { redirect: to.fullPath }
    })
  } else if (
    to.matched.some(record => record.meta.redirectIfAlreadyLoggedIn) &&
    isAuthenticated
  ) {
    next({
      name: 'Dashboard'
    })
  } else {
    if (!checkIfRouteHasGroup(isAuthenticated, to)) {
      next({
        name: 'Dashboard'
      })
    }

    checkMFASetup(next, to) // make sure to always call next()!
  }
})

const originalPush = router.push
router.push = function push(location, onResolve, onReject)
{
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject)
  }
 
  return originalPush.call(this, location).catch((err) => {
    if (VueRouter.isNavigationFailure(err)) {
      return err
    }
   
    return Promise.reject(err)
  })
}

export default router
