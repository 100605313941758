<template>
  <div>
    <Panel size="small">
      <template v-slot:header>
        <span data-cy="heading">Blocked Channels</span>
      </template>

      <template v-slot:content>
        <b-row>
          <b-table
            :fields="fields"          
            :items="blockedChannels"
            responsive
            :busy.sync="isLoading"
            thead-class="text-nowrap"
            tbody-tr-class="text-nowrap"
            primary-key="name"
          >
            <template #cell(actions)="row">
              <b-button            
                variant="success"
                size="sm"
                class="mr-3"            
                @click="showUnblockChannelModal(row.item)"
              >
                Unblock
              </b-button>
            </template>
          </b-table>
        </b-row>
      </template>
    </Panel>

    <b-modal
      id="confirm-unblock-channel-modal"
      ref="confirm-unblock-channel-modal"
      header-class="custom-modal"
      body-class="custom-modal"
      footer-class="custom-modal"
      title="Confirm Unblock Channel"
      ok-title="Unblock Channel"
      button-size="sm"
      @ok="confirmUnblockChannel"
    >
      <p
        v-if="selectedChannel && selectedChannel.name"
        class="mb-2"
      >
        Are you sure you want to unblock <strong>{{ selectedChannel.name }}</strong> channel?
      </p>

      <template #modal-footer>
        <b-button
          type="button"
          variant="secondary"
          size="sm"
          :disabled="isLoading"
          @click="$bvModal.hide('confirm-unblock-channel-modal')"
        >
          Cancel
        </b-button>

        <BaseButtonLoader
          :loading="isLoading"
          class="ml-2"
          type="button"
          :custom-button="false"
          size="sm"
          variant="success"
          @click="confirmUnblockChannel"
        >
          Unblock
        </BaseButtonLoader>
      </template>
    </b-modal>   
  </div> 
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { showSuccessMessage, showErrorMessage } from '@/notification-utils'

export default {
  name: 'BlockedChannels',

  title: 'Blocked Channels',

  data() {
    return {
      fields: [     
        { key: 'name', label: 'Channel Name', sortable: true }, 
        { key: 'categoryName', label: 'Category', sortable: true },
        { key: 'trafficSourceName', label: 'Traffic Source', sortable: true },
        { key: 'country', label: 'Country', sortable: true },       
        { key: 'language', label: 'Langauge', sortable: true },
        { key: 'actions', label: 'Actions', sortable: false } 
      ],
      isLoading: false,
      selectedChannel: {}
    }
  },

  computed: {
    ...mapState('callLogs', [ 'blockedChannels' ]),
  },

  async created() {
    await this.loadChannels()
  }, 

  methods: {
    ...mapActions('callLogs', [
      'getBuyerBlockedChannels',
      'unblockChannel'
    ]),

    async loadChannels() {
      try {
        this.isLoading = true
        await this.getBuyerBlockedChannels()
      }
      catch(error) {
        if (error.response.status === 500) {
          showErrorMessage(`Unable to load blocked channels`)
        }
        else {
          showErrorMessage(error.response.data.title)
        }
      }
      finally {
        this.isLoading = false
      }      
    },

    showUnblockChannelModal(channel) {
      this.selectedChannel = channel
      this.$bvModal.show('confirm-unblock-channel-modal')
    },
    
    async confirmUnblockChannel() {
      try {
        await this.unblockChannel(this.selectedChannel.name)
        showSuccessMessage('Successfully unblocked channel ' + this.selectedChannel.name)
        await this.loadChannels()
      }
      catch (error) {
        if (error.response.status === 500) {
          showErrorMessage(`Unable to unblock a channel`)
        }
        else {
          showErrorMessage(error.response.data.title)
        }        
      }
      finally {
        this.$bvModal.hide('confirm-unblock-channel-modal')
      }
    },
  }
}

</script>

