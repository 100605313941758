import BuyerCallLogsApi from '@/api/buyer-call-logs.api'

const CALL_LOGS_REPORTING_TIMEZONE_LOCAL_STORAGE_KEY = `call-logs-timezone-preference`


function getDefaultTimeZone() {
  return 'Eastern Standard Time'
}

/**
 * Get default date range of last 7 days
 * @returns {[startDate: Date, endDate: Date]} Date range
 */
function getDefaultDateRange() {
  const from = new Date(new Date().setHours(0,0,0,0) - 7 * 24 * 60 * 60 * 1000)
  from.setSeconds(0)

  const to = new Date(new Date().setHours(23,59,59,999))
  to.setSeconds(0)

  return [
    from,
    to
  ]
}

const state = {
  dateRange: getDefaultDateRange(),
  timeZone: getDefaultTimeZone(),
  autoRefresh: false,
  blockedChannels: []
}

const getters = {
  
}

/** 
 * @type {import('vuex').ActionTree<typeof state>} 
 */
const actions = {
  getCallLogsTimeZone({ commit, rootGetters }) {
    const currentAccountId = rootGetters['auth/accountId']
    const timeZonePreferenceFromLocalStorage = localStorage.getItem(`${CALL_LOGS_REPORTING_TIMEZONE_LOCAL_STORAGE_KEY}:${currentAccountId}`)
    const currentAccountTimeZone = rootGetters['auth/accountDetails']?.timeZoneId

    let timeZone = timeZonePreferenceFromLocalStorage ?? currentAccountTimeZone

    commit('SET_TIME_ZONE', timeZone ?? getDefaultTimeZone())
  },

  setCallLogsTimeZone({ commit, rootGetters }, timeZoneId) {
    const currentAccountId = rootGetters['auth/accountId']

    localStorage.setItem(`${CALL_LOGS_REPORTING_TIMEZONE_LOCAL_STORAGE_KEY}:${currentAccountId}`, timeZoneId)

    commit('SET_TIME_ZONE', timeZoneId)
  },

  async getBuyerBlockedChannels({ commit, rootGetters }) {
    const currentAccountId = rootGetters['auth/accountId']

    const response = await BuyerCallLogsApi.getBlockedChannels({
      accountId: currentAccountId
    })

    commit('SET_BLOCKED_CHANNELS', response?.data || [])
    return response?.data
  },

  async unblockChannel({ rootGetters }, channelName) {
    const currentAccountId = rootGetters['auth/accountId']

    await BuyerCallLogsApi.unblockChannel(currentAccountId, {
      channelName: channelName
    })
  }
}


const mutations = {
  SET_DATE_RANGE(state, dateRange) {
    state.dateRange = dateRange
  },

  SET_TIME_ZONE(state, timeZone) {
    state.timeZone = timeZone
  },

  TOGGLE_AUTO_REFRESH(state) {
    state.autoRefresh = !state.autoRefresh
  },

  SET_BLOCKED_CHANNELS(state, channels) {
    state.blockedChannels = channels  
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

