import httpClient from './httpClient'
import { getDateRangeInTimeZone } from '@/utils/dateTimeUtils'

const END_POINT = '/reporting/buyer'

const BuyerCallLogsAPI = {
  async getBuyerCallLogs(accountId, { from, to, timeZone, pageSize, pageNumber }) {
    const url = `${END_POINT}/${accountId}/calls`

    const { fromTime, toTime } = getDateRangeInTimeZone([from, to], timeZone)

    return httpClient.post(url, {
      dateFrom: fromTime,
      dateTo: toTime,
      timeZone,
      pageSize,
      pageNumber
    })
  },

  async getBuyerTotalCalls(accountId, { from, to, timeZone }) {
    const url = `${END_POINT}/${accountId}/total`

    const { fromTime, toTime } = getDateRangeInTimeZone([from, to], timeZone)

    return httpClient.post(url, {
      dateFrom: fromTime,
      dateTo: toTime
    })
  },

  async exportBuyerCalls(accountId, { from, to, timeZone }) {
    const url = `${END_POINT}/${accountId}/export`

    const { fromTime, toTime } = getDateRangeInTimeZone([from, to], timeZone)

    return httpClient.post(url, {
      dateFrom: fromTime,
      dateTo: toTime,
      timeZone
    })
  },

  async blockChannel(accountId, {callId, channel}) {
    const url = `${END_POINT}/${accountId}/channel/block`    

    return await httpClient.post(url, {
      callId: callId,
      channel: channel
    })
  },

  async unblockChannel(accountId, { channelName }) {
    const url = `${END_POINT}/${accountId}/channel/unblock`    

    return await httpClient.post(url, {      
      encodedName: channelName
    })
  },

  async getBlockedChannels({accountId}) {
    const url = `${END_POINT}/${accountId}/channel`    
    return await httpClient.get(url)
  },
}

export default BuyerCallLogsAPI