<template>
  <div class="py-5 text-center">
    <div class="title mb-1">
      Thank you for your submission.
    </div>
    <h5 class="mb-5">
      We'll reach out to you shortly.
    </h5>

    <BaseLink
      render-as-button
      to="login"
    >
      Back to Home
    </BaseLink>
  </div>
</template>

<script>
export default {
  name: 'RegistrationRequestSuccess',

  title: 'Application Submitted'
}
</script>

<style lang="scss" scoped>
.title {
  @include md-title(var(--white));
}
</style>